@import './variables.css';
@import './font.css';
@import './typography.css';
@import './util.css';
@import './design.css';
@import './table//table.css';

/* import tailwind layers */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  /* to offset header, we need a margin-top */
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--light-periwinkle);
  color: var(--blue-CPH);
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

}

.layout-wrapper {
  margin-top: 114px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: var(--primary-font);
}

.red-striped-container {
  width: 270px;
  height: 64px;
  position: absolute;
  left: 350px;
  color: var(--red);
  font-size: var(--text-md);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid var(--red);
  border-radius: 10px;
  font-family: var(--primary-font);
  background: repeating-linear-gradient(-45deg,
      #fee,
      #fee 11px,
      white 11px,
      white 22px);
}