.modal {
    background-color: var(--white);
    border-radius: var(--border-radius);
    width: 80%;
    max-width: 700px;
    color: var(--dark-blue-CPH);
    padding: 1rem 2rem;
}

.modalOverlay {
    width: 100vw;
    height: 100vh;
    background-color: var(--dark-blue-CPH-50);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}