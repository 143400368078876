.baggageInfoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.5rem 0;
}

.baggageInfoButton {
    font-size: 1.5rem;
}

.baggageInfoButton {
    width: 45%;
    padding-block: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--white);
    border: 2px solid var(--powder-blue);
    border-radius: 0.8rem;
    color: var(--dark-blue-CPH);
    margin: 0.8rem 0;
    position: relative;
    font-size: 18px;
    font-weight: normal;
}

.baggageInfoButtonYellow {
    background-color: var(--yellow-CPH-10);
    border: 2px solid var(--yellow-CPH);
}