.blueRoundedWrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    align-items: center;
    height: fit-content;
    padding: 1rem;
    margin-bottom: 2rem;
    background-color: var(--pale-grey-50);
    border-radius: 1rem;
}