.modalButton {
    width: 50%;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.5rem;
    color: var(--white);
    height: 3rem;
    font-size: 1.3rem;
}

.iconButton {
    width: 4rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tabButton {
    padding: 0 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.4rem;
    color: var(--dark-blue-CPH);
    font-size: 28px;
    font-weight: 500;
    min-height: 3.5rem;
}

.tabButtonActive {
    background-color: var(--dark-blue-CPH);
    color: var(--white);
}

.blueButton {
    background-color: var(--dark-blue-CPH);
    color: white;
    box-shadow: var(--blue-CPH-10) 0px 0px 0px 4px;
}

.whiteButton {
    background-color: var(--white);
    color: var(--dark-blue-CPH);
    box-shadow: var(--blue-CPH-10) 0px 0px 0px 4px;
}

.blueButtonNoBorder {
    background-color: var(--dark-blue-CPH);
    color: white;
}

.button__long {
    width: 200px !important;
}

.blueButton__disabled {
    background-color: var(--grey-CPH) !important;
}

.standardButton {
    border-radius: 0.3rem;
    height: 58px;
    font-size: 24px;
    padding: 4px;
    min-width: 172px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    font-weight: 700;
}

.beltNavigationButton {
    border-radius: 0.3rem;
    height: 64px;
    font-size: 32px;
    padding: 4px;
    min-width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8rem;
    font-weight: 500;
}

.standardButton:disabled {
    background-color: var(--grey-CPH) !important;
}

.smallButtonWidth {
    width: 7rem !important;
}

.beltNavigationGhostButton {
    border: none;
    background-color: transparent;
    color: var(--dark-blue-CPH);
    display: flex;
    justify-content: center;
    align-items: center;
}