.blue-dot {
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 0.3rem;
    background-color: var(--dark-blue-CPH);
}

.white-dot {
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 0.3rem;
    background-color: var(--white);
}

.grey-dot {
    width: 0.3rem;
    height: 0.3rem;
    border-radius: 1rem;
    background-color: var(--grey-CPH);
}

.time-tracker-timestamp {
    font-family: var(--secondary-font) !important;
    font-weight: normal !important;
    font-size: 1.5rem;
}

.button-disbale {
    border-color: var(--grey-CPH) !important;
    color: var(--grey-CPH) !important;
}

.no-bax-label {
    background-color: var(--green-CPH);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.5rem;
    padding: 0.5rem 1.5rem;
    gap: 1rem;
    border-radius: 5rem;
}

.blue-wrapper {
    background-color: var(--light-blue-10);
    color: var(--light-blue);
    padding: 1rem;
}

.red-wrapper {
    background-color: var(--red-10);
    color: var(--red);
    padding: 1rem;
}

.font-secondary {
    font-family: var(--secondary-font);
}