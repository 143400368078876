.slideUpList {
    background-color: var(--white);
    border-radius: 1rem 1rem 0 0;
    position: fixed;
    bottom: 0;
    padding: 1rem 52px;
    transform: translateY(0%);
    width: 100%;
    height: 7rem;
    box-shadow: rgba(51, 51, 51, 0.33) 0px -10px 12px;
    border: none;
    overflow-y: hidden;
    transition: height 0.6s;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slideUpListActive {
    height: 90vh;
}