.errorWrapper {
    background-color: white;
    border-radius: 0.3rem;
    justify-content: center;
    align-items: center;

}

.errorBox {
    background-color: var(--red-error-bg);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-radius: 0.3rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.errorMessage {
    color: var(--red)
}

.actionMessage {
    color: var(--dark-blue-CPH);
    font-size: 1.3rem;
}

.beltErrorWrapper {
    margin-top: 20px;
    width: 80%;
    background-color: var(--red-error-bg);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 10px;
    padding: 20px;
}

.beltErrorItem {
    display: flex;
    gap: 1rem;
    color: var(--red);
    font-size: 24px;
}