@font-face {
    font-family: 'Roboto';
    src: url(../assets/font/Roboto-Regular.ttf) format('truetype');
    ;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../assets/font/Roboto-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../assets/font/Roboto-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url(../assets/font/Roboto-Bold.ttf) format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-mono';
    src: url(../assets/font/RobotoMono-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-mono';
    src: url(../assets/font/RobotoMono-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto-mono';
    src: url(../assets/font/RobotoMono-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}