.table {
    width: 100%;
    table-layout: fixed;
}

.tableBorder {
    border: 0.15rem solid var(--grey-CPH);
    border-radius: 0.6rem;
    padding: 0;
    overflow: hidden;
}


.completed-list {
    background-color: white;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -20%);
    width: 80%;
    border-radius: 1rem;
    height: 70%;
    margin: 0;
    transition: 0.6s;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.completed-list__closed {
    transform: translate(-50%, 0%);
    bottom: 0;
    top: unset;
    height: 5.5rem;
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
}

.completed-list__modal {
    height: 80%;

}

#completed-list-scroll-wrapper {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    border: 1px solid var(--grey-CPH);
    border-radius: 10px;
    max-height: 95%;
    height: fit-content;
    overflow-y: scroll;
}

#completed-list-scroll-wrapper .table {
    border-spacing: 0;
    border-collapse: separate;
    max-height: 95%;
    overflow: hidden;
}


/* Hide scrollbar for Chrome, Safari and Opera */
#completed-list-scroll-wrapper::-webkit-scrollbar {
    display: none;
}

.completed-list__headline {
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 32px;
}

.completed-list__box-shadow {
    box-shadow: var(--light-periwinkle) 0px -3px 6px;
}

#completed-list {
    z-index: 1000;
}

.list-box-wrapper {
    width: 80%;
    margin-top: 2rem;
}